<script>
export default {
  name: 'SelectField',
  components: {
    Icon: () => import('@/components/general/Icon'),
    Loading: () => import('@/components/general/Loading'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    value: {
      default: null
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    label: {
      type: String
    },

    floatingLabel: {
      type: Boolean,
      default: true
    },

    showMessageWarning: {
      type: Boolean,
      default: false
    },

    messageWarning: {
      type: String,
      default: ''
    },

    hint: {
      type: String,
      default: null
    },

    multiple: {
      type: Boolean,
      default: false
    },

    selectAllOption: {
      type: Boolean,
      default: false
    },

    maxItensVisible: {
      type: Number,
      default: 3
    },

    items: {
      type: Array,
      default: () => {
        return []
      }
    },

    showCheck: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    underDescription: {
      type: String,
      default: null
    },

    searcheable: {
      type: Boolean,
      default: null
    },

    integration: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isMounted: false,
      dropdownOpen: false,
      focus: false,
      mutableValue: null,
      isSearching: false,
      mutableItems: [],
      keyIndex: -1
    }
  },

  computed: {
    hasValue () {
      return (this.multiple && this.mutableValue.length > 0) || (!this.multiple && this.mutableValue !== null)
    },

    isValid () {
      return this.validation.$error === false && this.validation.$dirty && this.showCheck
    },

    isUniqueInsideForm () {
      return this.isMounted && this.$refs.input.form == null
    },

    isAllOptionSelected () {
      return this.multiple && this.mutableValue.length === this.items.length
    },

    isRequired () {
      return this.validation.$params != null && typeof this.validation.$params.required === 'object'
    },

    filteredMutableItems () {
      return this.mutableItems.filter((item) => {
        return (!this.isOptionSelected(item) || this.multiple) && item.visible !== false
      })
    }
  },

  watch: {
    items () {
      this.mutableValue = this.value
    },

    multiple () {
      this.mutableValue = []
    },

    value (value) {
      this.mutableValue = value
    }
  },

  created () {
    this.mutableValue = this.value !== null ? this.value : this.multiple ? [] : null
    this.mutableItems = this.items
  },

  mounted () {
    this.isMounted = true
  },

  methods: {
    openDropdown () {
      if (this.searcheable && this.hasValue) {
        this.$refs.input.value = ''
      }

      this.mutableItems = this.items
      this.isSearching = false

      if (!this.disabled) {
        this.dropdownOpen = true

        if (!this.hasValue) {
          this.keyIndex = -1
        }
      }
    },

    clickLabel () {
      this.openDropdown()
      this.addFocus()
    },

    closeDropdown () {
      this.dropdownOpen = false
    },

    selectOption (item, index) {
      this.keyIndex = index

      if (this.multiple) {
        const indexArray = this.mutableValue.indexOf(item.value)

        indexArray >= 0 ? this.mutableValue.splice(indexArray, 1) : this.mutableValue.push(item.value)
      } else {
        this.mutableValue = item.value
        this.closeDropdown()
      }

      this.$emit('input', this.mutableValue)
      this.change(item)
    },

    change (item) {
      this.isSearching = false
      this.$emit('change', item)
    },

    clearValue () {
      if (this.disabled) return
      this.mutableValue = this.multiple ? [] : null
      this.$emit('input', this.multiple ? [] : null)
      this.dropdownOpen = false
      this.removeFocus()
      this.$emit('clear')
    },

    selectAll () {
      const deselect = this.isAllOptionSelected

      this.mutableItems.forEach((item, index) => {
        const hasChecked = this.mutableValue.indexOf(item.value) >= 0

        if ((deselect && hasChecked) || (!deselect && !hasChecked)) {
          this.selectOption(item, index)
        }
      })
    },

    isOptionSelected (item) {
      return (this.multiple && this.mutableValue.indexOf(item.value) >= 0) || this.mutableValue === item.value
    },

    getValues () {
      if (this.isSearching) {
        return this.$refs.input.value
      }

      if (this.multiple && this.mutableValue.length >= this.maxItensVisible) {
        return this.$t('global:select.multiple.values', { num: this.mutableValue.length })
      } else if (this.mutableValue !== null) {
        const tempMutableValue = this.multiple ? this.mutableValue : [ this.mutableValue ]

        return this.items.filter(item => {
          return tempMutableValue.indexOf(item.value) >= 0
        }).map(function (item) {
          return item.text
        }).toString().replace(/,/g, ', ')
      }
    },

    keyUp () {
      if (this.dropdownOpen && this.keyIndex > 0) {
        this.keyIndex--
      }
    },

    keyDown () {
      if (this.dropdownOpen && this.keyIndex < this.items.length) {
        this.keyIndex++
      } else {
        this.dropdownOpen = true
        this.keyIndex = this.multiple ? 0 : 1
      }
    },

    keyEnter () {
      if (!this.dropdownOpen) {
        this.dropdownOpen = true

        if (this.keyIndex < 0) {
          this.keyIndex = 0
        }
      } else {
        if (this.keyIndex > 0) {
          this.selectOption(this.items[this.keyIndex - 1], this.keyIndex)
        } else if (this.keyIndex === 0) {
          this.selectAll()
        } else {
          this.closeDropdown()
        }
      }
    },

    search (event) {
      this.isSearching = true
      const searchValue = this.$refs.input.value + event.key

      this.mutableItems = this.items

      if (searchValue.length >= 3) {
        this.mutableItems = this.items.filter((select) => {
          return select.text.toLocaleLowerCase().includes(searchValue)
        })
      }
    },

    highlight (index) {
      this.keyIndex = index
    },

    removeHighlight () {
      this.keyIndex = -1
    },

    addFocus () {
      if (!this.disabled) {
        this.focus = true
        typeof this.validation.$reset === 'function' && this.validation.$reset()
      }
    },

    removeFocus () {
      if (this.focus) {
        this.focus = false
        typeof this.validation.$touch === 'function' && this.validation.$touch()
      }
    },

    outside () {
      this.closeDropdown()
      this.removeFocus()
    },

    verifyDropdown () {
      if (this.dropdownOpen) {
        this.outside()

        return
      }

      this.clickLabel()
    },

    checkForTabOut (e) {
      if (e.keyCode === 9) {
        this.closeDropdown()
        this.removeFocus()
      }
    }
  }
}
</script>

<template>
  <div
    v-click-outside="outside"
    class="form-item"
    :class="{ 'has-value': hasValue, 'has-focus': focus, 'has-error': validation.$error, 'has-floating-label': floatingLabel, 'is-disabled': disabled, 'theme-dark': dark }"
  >
    <label
      v-if="label"
      tabindex="-1"
      class="form-label"
      @click="clickLabel()"
    >{{ label }} <span v-if="!isRequired && !disabled && !isUniqueInsideForm">{{ $t('global:form.optional') }}</span></label>
    <div
      tabindex="-1"
      class="form-input-wrapper form-select-wrapper"
      :class="{ 'is-multiselect': multiple, 'is-opened': dropdownOpen }"
      @blur="closeDropdown(), removeFocus()"
    >
      <input
        ref="input"
        class="form-input"
        :readonly="!searcheable"
        :value="getValues()"
        :tabindex="disabled ? -1 : 0"
        :class="{'border-color-error': showMessageWarning}"
        @click="openDropdown(), addFocus()"
        @focus="openDropdown(), addFocus()"
        @keypress="search($event)"
        @keydown.down.prevent="keyDown()"
        @keydown.enter.prevent="keyEnter()"
        @keydown.up.prevent="keyUp()"
        @keyup.27="closeDropdown()"
        @keydown="checkForTabOut($event)"
      >
      <ul
        v-if="dropdownOpen"
        class="form-select-dropdown"
      >
        <li
          v-if="mutableItems.length === 0 && !loading"
          class="form-select-dropdown-item"
          :class="{ 'is-highlight': keyIndex == 0 }"
          @click.stop="closeDropdown(), removeFocus()"
          @mouseover="highlight(0)"
          @mouseleave="removeHighlight()"
        >
          {{ $t('global:form.select.no.options') }}
        </li>
        <li
          v-if="multiple && selectAllOption"
          class="form-select-dropdown-item"
          :class="{ 'is-highlight': keyIndex == 0 }"
          @click.stop="selectAll()"
          @mouseover="highlight(0)"
          @mouseleave="removeHighlight()"
        >
          <Icon
            v-if="!isAllOptionSelected"
            name="check_box_outline_blank"
          />
          <Icon
            v-if="isAllOptionSelected"
            name="check_box"
          />
          {{ $t('global:form.select.all') }}
        </li>
        <li
          v-for="(item, index) in filteredMutableItems"
          :key="index"
          class="form-select-dropdown-item"
          :class="{ 'is-highlight': keyIndex == index + 1 }"
          :disabled="item.disabled"
          @click.stop="selectOption(item, index + 1)"
          @mouseover="highlight(index + 1)"
          @mouseleave="removeHighlight()"
        >
          <Icon
            v-if="multiple && !isOptionSelected(item)"
            name="check_box_outline_blank"
          />
          <Icon
            v-if="multiple && isOptionSelected(item)"
            name="check_box"
          />
          {{ item.text }}
        </li>
        <li
          v-if="!multiple && (mutableItems.length <= 1 && hasValue && !integration)"
          class="form-select-dropdown-item empty-message"
        >
          {{ $t('global.selectfield:no.other.options') }}
        </li>
        <li
          v-if="loading"
          class="form-select-dropdown-item is-loading"
        >
          <Loading />
        </li>
      </ul>
      <div
        class="form-input-append"
        @click="verifyDropdown()"
      >
        <Icon name="arrow_drop_down" />
        <Icon
          v-if="isValid"
          name="check"
          wrapper
        />
        <a
          v-if="hasValue && !disabled"
          tabindex="-1"
          href="javascript:void(0);"
          @click="clearValue"
        ><Icon name="close" /></a>
      </div>
    </div>
    <div class="form-input-details">
      <slot name="details" />
      <p
        v-if="underDescription"
        class="form-input-subtext"
      >
        {{ underDescription }}
      </p>
      <span
        v-if="showMessageWarning"
        class="message-warning text-opacity-70"
      >{{ $t(`${messageWarning}`) }}</span>
      <span
        v-if="hint && !validation.$error && !$slots.details"
        class="form-input-hint"
      >{{ hint }}</span>
      <ValidationMessage :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style src="@/assets/styles/themes/default/select.css"></style>
